<template>
  <div class="show-columns">
    <div class="show-columns__action" @click="fnShowColumns()" v-click-outside="checkOutsideEl">
      <span class="icon"
        ><svg
          class="svg-inline--fa fa-filter"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="filter"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            class=""
            fill="currentColor"
            d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"
          ></path></svg
      ></span>
      <span
        class="name-column-filter"
        :class="{ selectNameFilter: isSelectNameFilter }"
        >{{ nameColumnsFilter }}</span
      >
      <span class="icon angle"
        ><svg
          class="svg-inline--fa fa-angle-down"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="angle-down"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          :class="{ rotateIcon: isShowColumns }"
        >
          <path
            class=""
            fill="currentColor"
            d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
          ></path></svg
      ></span>
    </div>
    <div class="show-columns__content" v-show="isShowColumns" v-click-outside="hidePopup">
      <div
        v-for="item in props.listColumns"
        :key="item.value"
        class="show-columns__item"
        @click="fnChangeShowColumn(item.label, item.value, item.type)"
      >
        <span>{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from "vue";
// import { useRoute } from "vue-router";

const emit = defineEmits([""]);
// const route = useRoute();
const props = defineProps({
  listColumns: Array,
  countCloseListFilter: Number,
  nameFilterURL: String,
});

const isShowColumns = ref(false);
const isSelectNameFilter = ref(false);
const nameColumnsFilter = ref(null);
const check = ref(false);

const fnShowColumns = () => {
  isShowColumns.value = !isShowColumns.value;
  check.value = false;
  emit("closeListColumnsName");
  emit("closeWarningData");
};
const fnChangeShowColumn = (nameColumn, valueColumn, typeColumn) => {
  nameColumnsFilter.value = nameColumn;
  isSelectNameFilter.value = true;
  isShowColumns.value = false;
  emit("nameColumnToFilter", nameColumn, valueColumn, typeColumn);
};

const checkNameColumn = () => {
  const urlParams = window.location.search.slice(1);
    const searchParams = new URLSearchParams(urlParams);
    const filter_value = searchParams.get("$colfilter");
    if (filter_value) {
      let key = filter_value.slice(0, filter_value.indexOf(":"));
      let valueColumn = {};
      props.listColumns.forEach((item) => {
        if (item.value === key) {
          nameColumnsFilter.value = item.label;
          valueColumn = item;
        }
      });
      fnChangeShowColumn(valueColumn?.label, valueColumn?.value, valueColumn?.type);
    emit("nameColumnToFilter", valueColumn?.label, valueColumn?.value, valueColumn?.type);
    } else {
      fnChangeShowColumn(props.listColumns[0]?.label, props.listColumns[0]?.value, props.listColumns[0]?.type);
    emit("nameColumnToFilter", props.listColumns[0]?.label, props.listColumns[0]?.value, props.listColumns[0]?.type);
    }
};

const checkOutsideEl = () => {
  check.value = true;
};

const hidePopup = () => {
  if(isShowColumns.value && check.value) isShowColumns.value = false;
};


watch(
  () => props.countCloseListFilter,
  () => {
    isShowColumns.value = false;
  }
);

watch(
  () => props.listColumns,
  () => {
    checkNameColumn();
  }
);

onMounted(() => {
  setTimeout(() => {
    checkNameColumn();
  }, 100);
});
</script>
<style scoped lang="scss">
.show-columns {
  cursor: pointer;

  &__action {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    border: thin solid #dbdbdb;
    width: 210px;
    height: 34px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #fff;
    color: #4a4a4a;
    &:hover {
      border: thin solid #b5b5b5;
    }
  }
  &__content {
    min-width: 210px;
    background-color: #fff;
    box-shadow: 0 0.5em 1em -0.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
    border-radius: 4px;
    position: absolute;
    z-index: 4;
    top: 40px;
    & i.ri-check-line {
      color: #4dc78f;
      font-weight: bold;
      font-size: 18px;
      display: none;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px;
    height: 33px;
    cursor: pointer;
    transition: 0.2s;
    font-size: 14px;
    &:hover {
      background-color: #ededed6b;
    }
  }
}
.rotateIcon {
  transform: rotate(180deg);
}
.icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  svg {
    height: 16px;
    width: 18px;
  }
  .fa-angle-down {
    transition: 0.2s;
  }
}
.name-column-filter {
  color: #b3b2b7;
}
.selectNameFilter {
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
  text-overflow: ellipsis;
  text-align: center;
}
</style>
